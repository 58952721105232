<div id="schedule-appointment">
    <div class="container">
        <div class="white-logo">

        </div>
        <div class="new-appointment-sec">
            <ng-container *ngIf="!ifNoPatientDetails">
                <div class="no-patient-details-error">
                    <span class="error">{{noPatientDetailsMsg}}</span>
                </div>
            </ng-container>
            <div class="add-appointment-card">
                <!-- facility Name and facilityAddress    -->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="facilityHeader" *ngIf="facilityName && (address?.street || address?.zipCode)">
                            <h4 class="book-head">{{facilityName || ''}}</h4>
                            <div class="facilityAddress">
                                <span class="fa fa-map-marker"></span>&nbsp;&nbsp;
                                <span class="book-head">{{address?.street || ' '}}, {{ address?.city ||
                                    ' ' }},
                                    {{address?.state || ' ' }} - {{address?.zipCode || ' '}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Appointment and Demographics details -->
                <section id="demographics-details" *ngIf="displayPatientInfo">
                    <form [formGroup]="bookAppointmentForm">
                        <div class="bg-style demographics">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Patient Information</h5>
                                </div>
                            </div>
                            <!-- demographics row start -->
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div *ngIf="facilityId === '83' || facilityId === 83" class="form-group mb-3">
                                        <label>Are you experiencing any of the following breast concerns?
                                        </label><br />
                                        <label class="mt-2"> - Specific points of pain</label><br />
                                        <label> - Lumps you can feel</label><br />
                                        <label> - Nipple discharge</label><br />


                                        <ng-container>
                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" type="radio" name="isPurpose"
                                                    (change)="getPurposeOfVisit($event)" value="Yes" id="scheduleApp_Yes">
                                                <label class="form-check-label" for="Yes">Yes</label>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="isPurpose"
                                                    (change)="getPurposeOfVisit($event)" value="No" id="scheduleApp_No" checked>
                                                <label class="form-check-label" for="No">No</label>

                                            </div>

                                            <span
                                                *ngIf="selectedVal === 'Yes' && (!isAwhMammogramQue || isAwhMammogramQue !== 'Yes')"><br /><br />
                                                We would like you to book an appointment with one of our providers and
                                                call the practice at <b>225-201-0505</b> to
                                                describe your issue to our staff.
                                            </span>
                                        </ng-container>
                                        <br /><br>
                                        <!-- a New question for AWH -->
                                        <label>Have you had a diagnostic mammogram (as opposed to a general screening
                                            mammogram) in the past?</label><br />

                                        <ng-container>
                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" type="radio"
                                                    name="isDiagnosticMammogram" (change)="awhMammogramQue($event)"
                                                    value="Yes" id="scheduleApp_Yes1">
                                                <label class="form-check-label" for="Yes">Yes</label>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio"
                                                    name="isDiagnosticMammogram" (change)="awhMammogramQue($event)"
                                                    value="No" id="scheduleApp_No1" checked>
                                                <label class="form-check-label" for="No">No</label>

                                            </div>

                                            <span *ngIf="isAwhMammogramQue === 'Yes'"><br /><br />
                                                We would like you to book an appointment with one of our providers and
                                                call the practice at <b>225-201-0505</b> to
                                                describe your issue to our staff.
                                            </span>
                                        </ng-container>

                                    </div>

                                    <!-- Only For 85 FAcility -->
                                    <ng-container *ngIf="facilityId === '85' || facilityId === 85">
                                        <label>Are you experiencing any of the following breast concerns?
                                        </label><br />
                                        <label class="mt-2"> - Specific points of pain</label><br />
                                        <label> - Lumps you can feel</label><br />
                                        <label> - Nipple discharge</label><br />

                                        <ng-container>
                                            <div class="form-check form-check-inline mt-2">
                                                <input class="form-check-input" type="radio" name="isPurpose"
                                                    (change)="newFacilityChange($event)" value="Yes" id="scheduleApp_Yes2">
                                                <label class="form-check-label" for="Yes">Yes</label>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="isPurpose"
                                                    (change)="newFacilityChange($event)" value="No" id="scheduleApp_No2" checked>
                                                <label class="form-check-label" for="No">No</label>
                                            </div>

                                            <span *ngIf="is85FacilityChange === 'Yes'"><br /><br />
                                                We would like you to call the practice at
                                                <b>703-941-0267</b>
                                                to describe your issue before booking an appointment
                                            </span>
                                        </ng-container>
                                        <br /><br>
                                    </ng-container>

                                    <div *ngIf="facilityId === '90' || facilityId === 90 || facilityId === '85' || facilityId === 85"
                                        class="form-group mb-3">
                                        <div class="mb-3">Have you had a mammogram at another facility in the past 12
                                            months?</div>
                                        <ng-container>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="isMammogram"
                                                    (change)="checkValue($event)" value="Yes" id="scheduleApp_Yes3">
                                                <label class="form-check-label" for="Yes">Yes</label>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="isMammogram"
                                                    (change)="checkValue($event)" value="No" id="scheduleApp_No3" checked>
                                                <label class="form-check-label" for="No">No</label>
                                            </div>
                                            <div class="mb-2" *ngIf="selectedVal == 'Yes'">If yes, where did you have
                                                the mammogram?</div>
                                            <textarea *ngIf="selectedVal == 'Yes'" formControlName="notes"
                                                class="form-control" type="text"
                                                placeholder="Please provide the details" id="scheduleApp_mammogramNotes"></textarea>
                                            <!-- <span *ngIf="bookAppointmentForm?.get('notes')?.errors?.required && bookAppointmentForm?.get('notes')?.touched"
                                                class="error">Notes is Required</span> -->
                                        </ng-container>
                                    </div>

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <!-- Purpose of Visit [readonly]="disablePurposeOfVisit"-->
                                    <div class="form-group mb-3">
                                        <label>Purpose of Visit <span class="required">*</span></label>
                                        <ng-select placeholder="Choose Purpose of Visit"
                                            formControlName="PURPOSE_OF_VISIT" (change)="changePurposeVisit()"
                                            id="PURPOSE_OF_VISIT" [items]="newPurposeOfVisit" bindLabel="id"
                                            bindValue="value">
                                        </ng-select>
                                        <span
                                            *ngIf="bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.errors?.required && bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.touched"
                                            class="error">Purpose of Visit is Required</span>
                                    </div>
                                </div>

                                <!-- First Name -->
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>First Name
                                            <span class="required">*</span></label>
                                        <input type="text" class="form-control input-text"
                                            formControlName="PATIENT_FIRST_NAME" id="PATIENT_FIRST_NAME"
                                            placeholder="First Name">
                                        <span
                                            *ngIf="bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.errors?.required && bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.touched"
                                            class="error">Patient First Name is Required</span>

                                        <span class="error"
                                            *ngIf="bookAppointmentForm?.get('PATIENT_FIRST_NAME')?.errors?.pattern">Please
                                            enter a valid First Name</span>
                                    </div>
                                </div>
                                <!-- Middle Name -->
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Middle Name</label>
                                        <input type="text" class="form-control input-text"
                                            formControlName="PATIENT_MIDDLE_NAME" id="PATIENT_MIDDLE_NAME"
                                            placeholder="Middle Name">
                                        <!-- <span
                                            *ngIf="bookAppointmentForm?.get('PATIENT_MIDDLE_NAME')?.errors?.required && bookAppointmentForm?.get('PATIENT_MIDDLE_NAME')?.touched"
                                            class="error">Patient Middle Name is Required</span> -->

                                        <span class="error"
                                            *ngIf="bookAppointmentForm?.get('PATIENT_MIDDLE_NAME')?.errors?.pattern">Please
                                            enter a valid Middle Name</span>
                                    </div>
                                </div>
                                <!-- Last Name -->
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Last Name <span class="required">*</span></label>
                                        <input type="text" class="form-control input-text"
                                            formControlName="PATIENT_LAST_NAME" id="PATIENT_LAST_NAME"
                                            placeholder="Last Name">
                                        <span
                                            *ngIf="bookAppointmentForm?.get('PATIENT_LAST_NAME')?.errors?.required && bookAppointmentForm?.get('PATIENT_LAST_NAME')?.touched"
                                            class="error">Patient Last Name is Required</span>

                                        <span class="error"
                                            *ngIf="bookAppointmentForm?.get('PATIENT_LAST_NAME')?.errors?.pattern">Please
                                            enter a valid Last Name</span>
                                    </div>
                                </div>
                                <!-- onkeydown="return false" (keyup.enter)="validateManualEnterdob($event, 'DATE_OF_BIRTH')"
                                            (mouseout)="validateManualEnterdob($event, 'DATE_OF_BIRTH')"-->
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Date of Birth<span class="required">*</span></label><br />
                                        <input type="date" id="datemax" name="datemax" [max]="maxAppointmentDate"
                                            class="form-control input-text" formControlName="DATE_OF_BIRTH"
                                            [min]="minDOB" id="DATE_OF_BIRTH" placeholder="Date of Birth"
                                            (keyup.enter)="validateManualEnterdob($event, 'DATE_OF_BIRTH')"
                                            (mouseout)="validateManualEnterdob($event, 'DATE_OF_BIRTH')"
                                            (blur)="validateManualEnterdob($event, 'DATE_OF_BIRTH')">
                                        <span
                                            *ngIf="bookAppointmentForm?.get('DATE_OF_BIRTH')?.errors?.required && bookAppointmentForm?.get('DATE_OF_BIRTH')?.touched"
                                            class="error">Date of Birth is Required</span>
                                        <span class="text-danger"
                                            *ngIf="bookAppointmentForm?.get('DATE_OF_BIRTH')?.errors?.invalidDate && bookAppointmentForm?.get('DATE_OF_BIRTH')?.touched">Please
                                            Enter Valid Date Of Birth</span>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Gender<span class="required">*</span></label>
                                        <ng-select placeholder="Choose Gender" formControlName="GENDER" id="GENDER"
                                            [items]="genderSelection" bindLabel="desc" bindValue="value">
                                        </ng-select>

                                        <span
                                            *ngIf="bookAppointmentForm?.get('GENDER')?.errors?.required && bookAppointmentForm?.get('GENDER')?.touched"
                                            class="error">Gender is Required</span>
                                    </div>
                                </div>
                                <!-- Marital Status -->
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Marital Status<span class="required"
                                                *ngIf="validationRequired">*</span></label>
                                        <ng-select placeholder="Marital Status" formControlName="MARITAL_STATUS"
                                            id="MaritalStatus" [items]="maritalStatusSelection" bindLabel="desc"
                                            bindValue="value"></ng-select>
                                        <span
                                            *ngIf="validationRequired && bookAppointmentForm?.get('MARITAL_STATUS')?.errors?.required && bookAppointmentForm?.get('MARITAL_STATUS')?.touched"
                                            class="error">Marital Status is Required</span>
                                    </div>
                                </div>
                                <!-- Ethnicity -->
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb3">
                                        <label>Ethnicity<span class="required"
                                                *ngIf="validationRequired">*</span></label>
                                        <ng-select placeholder="Choose Ethnicity" formControlName="ETHNICITY"
                                            id="Ethnicity" [items]="allEthnicity" bindLabel="desc" bindValue="value">
                                        </ng-select>

                                        <span
                                            *ngIf="validationRequired && bookAppointmentForm?.get('ETHNICITY')?.errors?.required && bookAppointmentForm?.get('ETHNICITY')?.touched"
                                            class="error">Ethnicity is Required</span>
                                    </div>
                                </div>
                                <!-- RACE -->
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Race<span class="required" *ngIf="validationRequired">*</span></label>
                                        <ng-select placeholder="Choose Race" formControlName="RACE" id="RACE"
                                            [items]="allRaceItems" bindLabel="desc" bindValue="value"></ng-select>

                                        <span
                                            *ngIf="validationRequired && bookAppointmentForm?.get('RACE')?.errors?.required && bookAppointmentForm?.get('RACE')?.touched"
                                            class="error">Race is Required</span>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-6 col-md-12 col-sm-12"></div> -->

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Street<span class="required">*</span></label>
                                        <input type="text" class="form-control input-text" autocorrect="off"
                                            autocapitalize="off" spellcheck="off" #search formControlName="ADDRESS"
                                            id="ADDRESS" placeholder="Street Address">
                                        <span
                                            *ngIf="bookAppointmentForm?.get('ADDRESS')?.errors?.required && bookAppointmentForm?.get('ADDRESS')?.touched"
                                            class="error">Street is Required</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label for="last-name">City <span class="error">*</span></label>
                                        <input class="form-control input-text" type="text" placeholder="City" id="City"
                                            formControlName="CITY" />
                                        <span class="error"
                                            *ngIf="bookAppointmentForm?.get('CITY')?.errors?.required && bookAppointmentForm?.get('CITY')?.touched">City
                                            is Required</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label for="last-name">State <span class="error">*</span></label>
                                        <input class="form-control input-text" type="text" placeholder="State"
                                            id="state" formControlName="STATE" />
                                        <span class="error"
                                            *ngIf="bookAppointmentForm?.get('STATE')?.errors?.required && bookAppointmentForm?.get('STATE')?.touched">State
                                            is Required</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>ZIP Code<span class="required">*</span></label>
                                        <input class="form-control input-text" formControlName="ZIPCODE" id="ZIPCODE"
                                            minlength="3" maxlength="5" placeholder="ZIP Code"
                                            (blur)="addPrefixZipCode(bookAppointmentForm?.get('ZIPCODE')?.value,'valid')">
                                        <span
                                            *ngIf="bookAppointmentForm?.get('ZIPCODE')?.errors?.required && bookAppointmentForm?.get('ZIPCODE')?.touched"
                                            class="error">ZIP Code is Required</span>
                                        <span
                                            *ngIf="bookAppointmentForm?.get('ZIPCODE')?.invalid && !bookAppointmentForm?.get('ZIPCODE')?.errors?.required"
                                            class="error">Please enter valid ZIP Code, it accepts five digit
                                            number</span>
                                        <!-- zipcodeValidationIfmorethan &&  -->
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Phone Number <span class="required">*</span></label>
                                        <input class="form-control input-text" type="text" mask="(000) 000-0000"
                                            placeholder="(xxx) xxx-xxxx" id="PHONE_NUMBER"
                                            formControlName="PHONE_NUMBER" />
                                        <span *ngIf="bookAppointmentForm?.get('PHONE_NUMBER')?.touched">
                                            <span
                                                *ngIf="bookAppointmentForm?.get('PHONE_NUMBER')?.value?.toString()?.length > 0 && !bookAppointmentForm?.get('PHONE_NUMBER')?.invalid"
                                                class="error">Always use Mobile phone for timely communication
                                                from your Doctor's Office</span>
                                            <span
                                                *ngIf="bookAppointmentForm?.get('PHONE_NUMBER')?.errors?.required && !bookAppointmentForm?.get('PHONE_NUMBER')?.invalid"
                                                class="error">Phone Number is Required</span>
                                            <span class="error"
                                                *ngIf="bookAppointmentForm?.get('PHONE_NUMBER')?.invalid && !bookAppointmentForm?.get('incorrectNumber')?.errors?.required">Enter
                                                a valid 10 digit Mobile Phone Number</span>
                                        </span>
                                    </div>
                                </div>

                                <!-- If Covid Vaccination slected as purpose -->
                                <ng-container
                                    *ngIf="bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.value === 'Covid19 Vaccination'">
                                    <div class="covid-conditions mb-3">
                                        <label>Do you have any of the following medical conditions
                                            (select all that apply)?<span class="required">*</span></label><br />
                                        <ng-container *ngFor="let condition of patientPreExistingConditions">
                                            <div class="form-group text-left list-group">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"
                                                        (change)="checkedPreExisting($event.target, condition.value)"
                                                        formControlName="PATIENT_PRE_EXISTING"
                                                        value="{{condition.value}}"
                                                        id="PATIENT_PRE_EXISTING{{condition?.desc}}">
                                                    <label class="form-check-label"
                                                        for="PATIENT_PRE_EXISTING{{condition?.desc}}">
                                                        {{condition?.desc}}
                                                    </label>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <span
                                            *ngIf="bookAppointmentForm?.get('PATIENT_PRE_EXISTING')?.errors?.required && bookAppointmentForm?.get('PATIENT_PRE_EXISTING')?.touched"
                                            class="error">At least one Pre Existing condition is
                                            Required</span>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-dm-12">
                                        <div class="radioBtnsDiv form-group mb-3">
                                            <label>Vaccine Dosage (Moderna) <span
                                                    class="required">*</span></label><br />
                                            <ng-container *ngFor="let item of covidVaccineDosage">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input"
                                                        formControlName="COVID_VACCINE_DOSAGE" type="radio"
                                                        (change)="checkPaymentType()" name="COVID_VACCINE_DOSAGE"
                                                        [value]="item.value" id="{{item?.desc}}">
                                                    <label class="form-check-label"
                                                        for="{{item?.desc}}">{{item?.desc}}</label>
                                                </div>
                                            </ng-container><br />
                                            <span
                                                *ngIf="bookAppointmentForm?.get('COVID_VACCINE_DOSAGE')?.errors?.required && bookAppointmentForm?.get('COVID_VACCINE_DOSAGE')?.touched"
                                                class="error">Vaccine Dosage Sequence is Required</span>
                                        </div>
                                    </div>
                                </ng-container>


                            </div>
                            <!-- demographics row end -->
                        </div>
                        <!-- Visit Type and Doctor Selection -->
                        <div class="bg-style demographics">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>VISIT TYPE AND DOCTOR</h5>
                                </div>
                                <div class="col-lg-12 col-md-12 col-sm-12">

                                    <div class="form-group mb-3">
                                        <label>Please select Type Of Visit <span class="required">*</span></label><br />
                                        <ng-container *ngFor="let service of typeOfServices">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="TYPE_OF_SERVICE"
                                                    type="radio" name="TYPE_OF_SERVICE"
                                                    (change)="typeOfServiceSelection()"
                                                    [attr.disabled]="(facilityId && (facilityId === '201' || facilityId === 201)) || providerworkflow?.regular == true ? 'true' : null"
                                                    [value]="service" id="{{service}}">
                                                <label class="form-check-label" for="{{service}}">{{service}}</label>
                                            </div>
                                        </ng-container><br />
                                        <span
                                            *ngIf="bookAppointmentForm?.get('TYPE_OF_SERVICE')?.errors?.required && bookAppointmentForm?.get('TYPE_OF_SERVICE')?.touched"
                                            class="error">Type of Service is Required</span>

                                        <span class="info-message" *ngIf="checkCovidVisit">Type of service should be
                                            <b>Regular Visit</b>
                                            because you have selected
                                            {{bookAppointmentForm?.get('PURPOSE_OF_VISIT')?.value}}
                                        </span>
                                    </div>


                                    <div *ngIf="!isurgentCare" class="form-group mb-3">
                                        <label>Please select Patient Type <span class="required">*</span></label><br />

                                        <ng-container *ngFor="let patient of typeOfPatient">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="TYPE_OF_PATIENT"
                                                    type="radio" name="TYPE_OF_PATIENT"
                                                    (change)="resetDoctorAndAppointmentFields('change')"
                                                    [value]="patient.value" id="{{patient?.desc}}">
                                                <label class="form-check-label"
                                                    for="{{patient?.desc}}">{{patient?.desc}}</label>
                                            </div>
                                        </ng-container><br />
                                        <span
                                            *ngIf="bookAppointmentForm?.get('TYPE_OF_PATIENT')?.errors?.required && bookAppointmentForm?.get('TYPE_OF_PATIENT')?.touched"
                                            class="error">Type of Patient is Required</span>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="bg-style demographics" *ngIf="visitSelected">

                            <!-- visit type row start -->
                            <div class="row" *ngFor="let vType of visitTypearr; let row = index">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>PURPOSE OF VISIT : <span class="link-txt">{{vType}}</span></h5>
                                </div>

                                <div *ngIf="!isurgentCare" class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group mb-3" id="doctorDiv">
                                        <label>Please select Doctor<span class="required">*</span></label>
                                        <ng-select placeholder="Doctor"
                                            (change)="changeDoctorName(vType, row, 'default',$event)"
                                            formControlName="DOCTOR" id="DOCTOR_{{row}}"
                                            (click)="doctorsDropDownClick()" [items]="doctorsList"
                                            bindLabel="doctorName" bindValue="npi" bindDesc="DR">
                                        </ng-select>
                                        <span
                                            *ngIf="bookAppointmentForm?.get('DOCTOR')?.errors?.required && bookAppointmentForm?.get('DOCTOR')?.touched"
                                            class="error">Doctor is Required</span>
                                    </div>
                                </div>

                                <ng-container *ngIf="kioskAddAppointment">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Schedule Type <span class="required">*</span></label><br />
                                            <ng-container *ngFor="let item of addPatientScheduleType">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" formControlName="SCHEDULE_TYPE"
                                                        type="radio" name="SCHEDULE_TYPE"
                                                        (change)="changeScheduleType()" [value]="item.value"
                                                        id="{{getlabelId(item?.desc)}}">
                                                    <label class="form-check-label"
                                                        for="{{item?.desc}}">{{item?.desc}}</label>
                                                </div>
                                            </ng-container><br />
                                            <span class="required"
                                                *ngIf="bookAppointmentForm?.get('SCHEDULE_TYPE')?.errors?.required && bookAppointmentForm?.get('SCHEDULE_TYPE')?.touched">Schedule
                                                Type is Required</span>
                                        </div>
                                    </div>


                                    <ng-container
                                        *ngIf="bookAppointmentForm?.get('SCHEDULE_TYPE')?.value === 'Future Appointment'">
                                        <div class="col-lg-6 col-md-12 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label>Appointment Date <span class="required">*</span></label><br />
                                                <input type="date" (change)="changeAppointmentDate('default',row)"
                                                (input)="validateAppointmentDate($event, 'APPOINTMENT_DATE')"
                                                name="datemin" [min]="minAppointmentDate"
                                                class="form-control input-text" id="visit-date"
                                                formControlName="APPOINTMENT_DATE" placeholder="Appointment Date">
                                            <span class="required"
                                                    *ngIf="bookAppointmentForm?.get('APPOINTMENT_DATE')?.errors?.required && bookAppointmentForm?.get('APPOINTMENT_DATE')?.touched">Appointment
                                                    Date is Required</span>                                
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label>Appointment Slot <span class="required">*</span></label>
                                                <ng-select placeholder="Choose Slot" id="scheduleApp_appSlot"
                                                    [items]="getAppointmentSlotsAvailable"
                                                    (change)="changeSlotTime('default',row)"
                                                    formControlName="APPOINTMENT_SLOT" bindLabel="descStartTime"
                                                    bindValue="startTime"></ng-select>
                                                <label class="required"
                                                    *ngIf="bookAppointmentForm.get('APPOINTMENT_SLOT')?.errors?.required && bookAppointmentForm?.get('APPOINTMENT_SLOT')?.touched">Appointment
                                                    Slot should be required</label>
                                            </div>
                                        </div>

                                    </ng-container>
                                </ng-container>

                                <!-- If direct open an to schdule an appointment -->
                                <ng-container
                                    *ngIf="!kioskAddAppointment && bookAppointmentForm?.get('DOCTOR')?.value && (availableSlots && availableSlots?.length > 0)">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Earliest Available Dates for the selected doctor </label><br />
                                            <ul class="time-slots">
                                                <li *ngFor="let slots of availableSlots;let i = index" id="timeSlots_{{getlabelId(vType)}}{{i}}"
                                                    (click)="setSelectedSlot(slots,i,'default',row); ">
                                                    <a>
                                                        {{slots | date: 'MM/dd'}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Appointment Date <span class="required">*</span></label><br />
                                        <input type="date" id="datemin" name="datemin" [min]="minAppointmentDate"
                                            class="form-control input-text"
                                            (change)="changeAppointmentDate('default',row)"
                                            (input)="validateAppointmentDate($event, 'APPOINTMENT_DATE')"
                                            formControlName="APPOINTMENT_DATE" id="APPOINTMENT_DATE"
                                            placeholder="Appointment Date">
                                        <span
                                            *ngIf="bookAppointmentForm?.get('APPOINTMENT_DATE')?.errors?.required && bookAppointmentForm?.get('APPOINTMENT_DATE')?.touched"
                                            class="error">Appointment Date is Required</span>
                                        
                                        <span
                                            *ngIf="bookAppointmentForm?.get('APPOINTMENT_DATE')?.errors?.invalidDate && bookAppointmentForm?.get('APPOINTMENT_DATE')?.touched"
                                            class="error">Date is invalid</span>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Appointment Slot <span class="required">*</span></label>
                                        <ng-select placeholder="Choose Appointment Slot"
                                            (change)="changeSlotTime('default',row)" formControlName="APPOINTMENT_SLOT"
                                            id="APPOINTMENT_SLOT" [items]="getAppointmentSlotsAvailable"
                                            bindLabel="descStartTime" bindValue="startTime"></ng-select>

                                        <span
                                            *ngIf="bookAppointmentForm?.get('APPOINTMENT_SLOT')?.errors?.required && bookAppointmentForm?.get('APPOINTMENT_SLOT')?.touched"
                                            class="error">Appointment Slot is Required</span>
                                    </div>
                                </div>


                                <!-- <div class="col-lg-12 col-md-12 col-sm-12 noAppnts errorMessage"
                                    *ngIf="appointmentsNotAvailable">
                                    <p class="required">No Appointments are available for selected date. Please choose
                                        other date.</p>
                                </div> -->

                            </div>
                            <!-- visit type row end -->

                            <div class="row" *ngIf="isFutureAppt && showWaitlistChk">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group">
                                        <input type="checkbox" name="waitList" id="waitList" formControlName="waitList">
                                        <label for="checkbox-fill-a1" class="cr p-2 save-credn-label"> Earliest
                                            Availability Waitlist</label>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="bg-style demographics" *ngIf="newVisitArr && newVisitArr.length !==0"
                            [ngClass]="!defaultChk ? 'disabled' : ''">

                            <!-- visit type row start -->
                            <div class="row" *ngFor="let vType of newVisitArr; let row = index">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>PURPOSE OF VISIT : <span class="link-txt">{{vType}}</span></h5>
                                </div>


                                <div *ngIf="!isurgentCare" class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group mb-3" id="doctorDiv_{{row}}">
                                        <label>Please select Doctor<span class="required">*</span></label>
                                        <ng-select placeholder="Doctor"
                                            (change)="changeDoctorName(vType, row, 'additional', $event)"
                                            formControlName="DOCTOR_{{row}}" id="DOCTOR_{{row}}"
                                            (click)="doctorsDropDownClick()" [items]="doctorsListAdd"
                                            bindLabel="doctorName" bindValue="npi" bindDesc="DR">
                                        </ng-select>
                                        <span
                                            *ngIf="bookAppointmentForm?.get('DOCTOR_'+row)?.errors?.required && bookAppointmentForm?.get('DOCTOR_'+row)?.touched"
                                            class="error">Doctor is Required</span>
                                    </div>
                                </div>
                                <div *ngIf="appointmentGap > 0 && defaultChk"> ( {{vType}} Must Be {{appointmentGap}}
                                    Days After Scheduled {{visitTypearr[0]}} )</div>
                                <ng-container
                                    *ngIf="!kioskAddAppointment &&  (availableSlotsAdd && availableSlotsAdd?.length > 0)">
                                    <div class="col-lg-12 col-md-12 col-sm-12" id="slotsDiv_{{row}}">
                                        <div class="form-group mb-3">
                                            <label>Earliest Available Dates for the selected doctor </label><br />
                                            <ul class="time-slots">
                                                <li *ngFor="let slots of availableSlotsAdd;let i = index" id="timeSlots_{{getlabelId(vType)}}{{i}}"
                                                    (click)="setSelectedSlot(slots,i,'additional',row)"
                                                    [ngClass]="!getDateFromString(slots) ? 'dtDisabled' : ''">
                                                    <a>
                                                        {{slots | date: 'MM/dd'}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-lg-6 col-md-12 col-sm-12" id="dateDiv_{{row}}"
                                    [ngClass]="!isDoctorSelected ? 'disabled' : ''">
                                    <div class="form-group mb-3">
                                        <label>Appointment Date <span class="required">*</span></label><br />
                                        <input type="date" id="c_datemin_{{row}}" name="datemin"
                                            [min]="minAppointmentDate" class="form-control input-text"
                                            (change)="changeAppointmentDate('additional',row)"
                                            (input)="validateAppointmentDate($event, 'APPOINTMENT_DATE')"
                                            formControlName="APPOINTMENT_DATE_{{row}}" id="APPOINTMENT_DATE"
                                            placeholder="Appointment Date">
                                        <span
                                            *ngIf="bookAppointmentForm?.get('APPOINTMENT_DATE_'+row)?.errors?.required && bookAppointmentForm?.get('APPOINTMENT_DATE_'+row)?.touched"
                                            class="error">Appointment Date is Required</span>

                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Appointment Slot <span class="required">*</span></label>
                                        <ng-select placeholder="Choose Appointment Slot"
                                            (change)="changeSlotTime('additional',row)"
                                            formControlName="APPOINTMENT_SLOT_{{row}}" id="APPOINTMENT_SLOT"
                                            [items]="getAppointmentSlotsAvailable" bindLabel="descStartTime"
                                            bindValue="startTime"></ng-select>
                                        <span
                                            *ngIf="bookAppointmentForm?.get('APPOINTMENT_SLOT_'+row)?.errors?.required && bookAppointmentForm?.get('APPOINTMENT_SLOT_'+row)?.touched"
                                            class="error">Appointment Slot is Required</span>

                                    </div>
                                </div>


                            </div>

                        </div>




                        <!--Patient Consent for Digital Notifications -->
                        <div class="bg-style demographics">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Preferred Language</h5>
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-12 col-sm-12">
                                <div class="form-group mb-3">
                                    <label>Language For Communication</label>
                                    <ng-select placeholder="Choose Preffered Language"
                                        formControlName="preferredLanguage" id="PREFFERED_LANGUAGE"
                                        [items]="languageList" bindLabel="desc" [clearable]="false"
                                        bindValue="value"></ng-select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Consent For Digital Delivery</h5>
                                </div>
                            </div>

                            <!-- visit type row start -->
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">

                                    <div class="form-group mb-3">
                                        <!-- <label>Please select one of the applicable Options <span
                                                class="required">*</span></label><br />  -->
                                        <ng-container>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="selectCheckbox"
                                                    [attr.disabled]="isdesabled ? 'true' : null"
                                                    formControlName="patientConsent">
                                                <label class="form-check-label" for="selectCheckbox">
                                                    Consent For Digital Delivery
                                                </label><br>
                                                <label>(All communications about Labs, Radiology orders, results,
                                                    clinical documentation, and pay statements will be delivered through
                                                    secure patient portals Via SMS)</label>
                                            </div>
                                        </ng-container><br />
                                    </div>
                                </div>
                            </div>
                            <!-- visit type row end -->
                        </div>

                        <!-- accect terms and conditions and continue -->
                        <div class="terms-conditions">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="form-group mb-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" [checked]="!iAgreeToTerms"
                                            (change)="iAgreeToTermsChecked($event.target)" id="createAccount">
                                        <label class="form-check-label" for="createAccount">
                                            I agree to
                                            create an account and have read, understand and
                                            agree to
                                            CareEco's <a href="/foot-terms-of-service"
                                                target="_blank">Terms of
                                                Service</a>,
                                            <span></span>
                                            <a href="/foot-privacy-policy" target="_blank"
                                                >Privacy Policy</a>
                                        </label>
                                        <span *ngIf="iAgreeToTerms && isFormSubmit " class="error">Please Accept the
                                            Terms and
                                            Conditions</span>
                                    </div>
                                </div>
                                <div class="form-group mb-3"
                                    *ngIf="bookAppointmentForm?.get('TYPE_OF_SERVICE')?.value === 'Tele-Medicine' || bookAppointmentForm?.get('TYPE_OF_SERVICE')?.value === 'Tele-Health'">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox"
                                            [checked]="!iAgreeToTelemedicineTerms"
                                            (change)="confirmingConsentForTelemedicine($event.target)"
                                            id="consent-form">
                                        <label class="form-check-label" for="consent-form">
                                            I give my
                                            consent for the use of Tele-Health in my medical care
                                            and have read, understand, and agree to these
                                            <a href="/foot-terms-of-telemedicine" target="_blank"
                                                >Tele-Health terms</a>
                                        </label>
                                        <span *ngIf="iAgreeToTelemedicineTerms && isFormSubmit" class="error"> Please
                                            Accept the
                                            Tele-Health Terms.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- "this.updatePannelFlags('displayInsuranceInfo');  -->
                        <div class="row" style="text-align: center;">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <span>
                                    <button class="btn btn-primary btn-sm scheduleAppBackBtn"   (click)="goBackFromscheduleAppointment()" id="scheduleAppBackBtn">Back</button>
                                </span>
                                <span>
                                    <button type="button" class="btn btn-primary continue-btn" id="scheduleApp_continue"
                                        (click)="checkIsEstablishedUserOrNot()">Continue</button>
                                </span>
                            </div>
                        </div>

                    </form>
                </section>


                <!-- Insurance details -->
                <section id="insurance-details" *ngIf="displayInsuranceInfo">
                    <form [formGroup]="bookAppointmentForm">
                        <div class="bg-style insurances">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <h5>Insurance Details</h5>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Coverage Type <span class="required">*</span></label><br />
                                        <ng-container *ngFor="let item of isSelfPay">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" formControlName="IS_SELF_PAY"
                                                    type="radio" (change)="checkPaymentType()" name="IS_SELF_PAY"
                                                    [value]="item.value" id="{{getlabelId(item?.desc)}}"
                                                    [attr.disabled]="item.value ==='healthPass' && isHealthpassDisabled ?'true':null">
                                                <label class="form-check-label"
                                                    for="{{item?.desc}}">{{item?.desc}}</label>




                                            </div>
                                        </ng-container><br />
                                        <span
                                            *ngIf="bookAppointmentForm?.get('IS_SELF_PAY')?.errors?.required && bookAppointmentForm?.get('IS_SELF_PAY')?.touched"
                                            class="error">Coverage Type is Required</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="facilityId == 83">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group mb-3">
                                        <label>Note: <span>We are not currently seeing Medicaid patients for GYN
                                                visits.</span></label>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="bookAppointmentForm?.get('IS_SELF_PAY')?.value === 'Insurance'">
                                <div class="row">
                                    <!-- <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Insurance Name <span class="required">*</span></label>
                                            <input type="text" list="Insurance_Name" (change)="setPayerId(insu.value)"
                                                #insu (input)="getInsurances()" formControlName="INSURANCE_NAME"
                                                class="form-control input-text" id="insurance_name_input"
                                                placeholder="Insurance Name">

                                            <datalist id="Insurance_Name">
                                                <option *ngFor="let insu of allInsurances" value="{{insu.name}}">
                                                </option>
                                            </datalist>
                                            <span
                                                *ngIf="bookAppointmentForm?.get('INSURANCE_NAME')?.errors?.required && bookAppointmentForm?.get('INSURANCE_NAME')?.touched"
                                                class="error">Insurance Name is Required</span>
                                        </div>
                                    </div> -->

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Insurance Name <span class="error">*</span></label>
                                            <ng-select placeholder="Search by Insurance Name or Payer ID or Plan Name"
                                                [items]="(allInsuranceList | async)?.responseObject" bindLabel="name"
                                                bindValue="name" [addTag]="true" [multiple]="false"
                                                [hideSelected]="false" [trackByFn]="trackByFn" [minTermLength]="3"
                                                [closeOnSelect]="true" (change)="selectedInsurance($event)"
                                                [loading]="insuranceLoading"
                                                typeToSearchText="Please enter 3 or more characters"
                                                [typeahead]="insuranceInput" clearAllText="Clear"
                                                formControlName="INSURANCE_NAME">
                                            </ng-select>
                                            <span
                                                *ngIf="bookAppointmentForm?.get('INSURANCE_NAME')?.errors?.required && bookAppointmentForm?.get('INSURANCE_NAME')?.touched"
                                                class="error">Insurance Name is Required</span>
                                            <span class="error"
                                                *ngIf="bookAppointmentForm?.get('INSURANCE_NAME')?.invalid && !bookAppointmentForm?.get('INSURANCE_NAME')?.errors?.required">Please
                                                enter a valid Insurance Name</span>
                                        </div>
                                    </div>


                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Member ID <span class="required">*</span></label>
                                            <input type="text" class="form-control input-text"
                                                formControlName="MEMBER_ID" id="MEMBER_ID" maxlength="25"
                                                placeholder="Member ID">
                                            <span
                                                *ngIf="bookAppointmentForm?.get('MEMBER_ID')?.errors?.required && bookAppointmentForm?.get('MEMBER_ID')?.touched"
                                                class="error">Member ID is Required</span>
                                            <span
                                                *ngIf="bookAppointmentForm?.get('MEMBER_ID')?.invalid && !bookAppointmentForm?.get('MEMBER_ID')?.errors?.required"
                                                class="error">Member ID accepts only Alphanumeric & Hyphen Symbol</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Group Number</label>
                                            <input type="text" class="form-control input-text"
                                                formControlName="GROUP_NUMBER" id="GROUP_NUMBER" maxlength="25"
                                                placeholder="Group Number">
                                            <span class="error"
                                                *ngIf="bookAppointmentForm?.get('GROUP_NUMBER')?.invalid && !bookAppointmentForm?.get('GROUP_NUMBER')?.errors?.required">
                                                Group Number accepts only Alphanumeric & Hyphen Symbol</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Payer ID <span class="required">*</span></label>
                                            <input type="text" class="form-control input-text"
                                                formControlName="PAYER_ID" id="PAYER_ID" placeholder="Payer ID">
                                            <span
                                                *ngIf="bookAppointmentForm?.get('PAYER_ID')?.errors?.required && bookAppointmentForm?.get('PAYER_ID')?.touched"
                                                class="error">Payer ID is Required</span>

                                            <span class="error"
                                                *ngIf="bookAppointmentForm?.get('PAYER_ID')?.invalid && !bookAppointmentForm?.get('PAYER_ID')?.errors?.required">
                                                Payer ID accepts only Alphanumeric & Hyphen Symbol</span>

                                            <!-- <span class="text-danger"
                                                *ngIf="bookAppointmentForm?.get('PAYER_ID')?.invalid && !bookAppointmentForm?.get('PAYER_ID')?.errors?.required">Please
                                                enter a valid Payer ID</span> -->
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Insurance Class <span class="required">*</span></label>
                                            <ng-select placeholder="Choose Insurance Class"
                                                formControlName="insuranceClass" id="insuranceClass"
                                                [items]="insuranceClassList">
                                            </ng-select>
                                            <span
                                                *ngIf="bookAppointmentForm?.get('insuranceClass')?.errors?.required && bookAppointmentForm?.get('insuranceClass')?.touched"
                                                class="error">Insurance Class is Required</span>
                                        </div>
                                    </div>


                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Insurance Primary Subscriber <span
                                                    class="required">*</span></label><br />
                                            <ng-container *ngFor="let item of insurancePrimarySubscriber">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input"
                                                        formControlName="PATIENT_PRIMARY_SUBSCRIBER" type="radio"
                                                        (change)="checkPrimarySubscriber()"
                                                        name="PATIENT_PRIMARY_SUBSCRIBER" [value]="item.value"
                                                        id="{{getlabelId(item?.desc)}}">
                                                    <label class="form-check-label"
                                                        for="{{item?.desc}}">{{item?.desc}}</label>
                                                </div>
                                            </ng-container>
                                            <br />
                                            <span
                                                *ngIf="bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.errors?.required && bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.touched"
                                                class="error">Insurance Primary Subscriber is Required</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Primary Subscriber First Name <span class="required">*</span></label>
                                            <input type="text" class="form-control input-text"
                                                formControlName="PRIMARY_SUBSCRIBER_FIRST_NAME"
                                                id="PRIMARY_SUBSCRIBER_FIRST_NAME"
                                                placeholder="Primary Subscriber First Name"
                                                [attr.disabled]="bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.value === 'Self' ? 'true' : null">
                                            <span
                                                *ngIf="bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.errors?.required && bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.touched"
                                                class="error">Primary Subscriber First Name is Required</span>
                                            <span class="error"
                                                *ngIf="bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_FIRST_NAME')?.errors?.pattern">Please
                                                enter a valid Primary Subscriber First Name</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Primary Subscriber Last Name <span class="required">*</span></label>
                                            <input type="text" class="form-control input-text"
                                                formControlName="PRIMARY_SUBSCRIBER_LAST_NAME"
                                                id="PRIMARY_SUBSCRIBER_LAST_NAME"
                                                placeholder="Primary Subscriber Last Name"
                                                [attr.disabled]="bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.value === 'Self' ? 'true' : null">
                                            <span
                                                *ngIf="bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.errors?.required && bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.touched"
                                                class="error">Primary Subscriber Last Name is Required</span>
                                            <span class="error"
                                                *ngIf="bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_LAST_NAME')?.errors?.pattern">Please
                                                enter a valid Primary Subscriber Last Name</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Primary Subscriber Date of Birth <span
                                                    class="required">*</span></label>
                                            <input type="date" class="form-control input-text" onkeydown="return true"
                                                id="datemaxprimary" name="datemax" [max]="maxAppointmentDate"
                                                formControlName="PRIMARY_SUBSCRIBER_DOB" id="PRIMARY_SUBSCRIBER_DOB"
                                                placeholder="Primary Subscriber Date of Birth"
                                                (input)="validateManualEnterdob($event,'PRIMARY_SUBSCRIBER_DOB')"
                                                [attr.disabled]="bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.value === 'Self' ? 'true' : null">
                                            <span
                                                *ngIf="bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.errors?.required && bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_DOB')?.touched"
                                                class="error">Primary Subscriber Date of Birth is Required</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Primary Subscriber Gender <span class="required">*</span></label>
                                            <ng-select placeholder="Primary Subscriber Gender"
                                                formControlName="PRIMARY_SUBSCRIBER_GENDER"
                                                id="PRIMARY_SUBSCRIBER_GENDER" [items]="genderSelection"
                                                bindLabel="desc" bindValue="value"
                                                [readonly]="bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.value === 'Self' ? true : false">
                                            </ng-select>
                                            <span
                                                *ngIf="bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.errors?.required && bookAppointmentForm?.get('PRIMARY_SUBSCRIBER_GENDER')?.touched"
                                                class="error">Primary Subscriber Gender is Required</span>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label for="Relationship"> Relationship to Subscriber <span
                                                    *ngIf="bookAppointmentForm?.get('PATIENT_PRIMARY_SUBSCRIBER')?.value === 'Other'"
                                                    class="error">*</span></label>

                                            <ng-select placeholder="Choose Relationship to Subscriber" id="scheduleApp_relationshipSubs"
                                                [items]="insuranceRelationship" formControlName="relationToSubscriber"
                                                bindLabel="desc" bindValue="value">
                                            </ng-select>

                                            <span
                                                *ngIf="bookAppointmentForm?.get('relationToSubscriber')?.errors?.required && bookAppointmentForm?.get('relationToSubscriber')?.touched"
                                                class="error">Relationship to Subscriber is Required</span>

                                        </div>
                                    </div>

                                    <ng-container *ngIf="getUserInfoIdWithPatientDetails">
                                        <h6 class="error">* Note: Changes to Existing Insurance are allowed at Doctor's
                                            office</h6>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="insuranceCardImageId !== '' && insuranceCardImageId !== null && insuranceCardImageId !== 'undefined'">
                                        <div class="col-lg-6 col-md-6 coll-sm-12">
                                            <h5 class="head-color">Existing Insurance Card</h5>
                                            <iframe id="insurance-card" frameBorder="0" width="100%"
                                                height="200px"></iframe>
                                        </div>
                                    </ng-container>

                                    <!-- Insurance Capture -->
                                    <div class="row col-lg-12 col-md-12 col-sm-12 mt-2">
                                        <ng-container *ngIf="providerworkflow?.insuranceImage">
                                            <div class=" col-lg-12 col-md-12 col-sm-12">
                                                <label> Insurance card <span class="cameranotes">(Capture both sides of
                                                        the Insurance card)</span></label>

                                                <div>
                                                    <img (click)="openInsuranceCamera();" *ngIf="!showInsuranceWebcam"
                                                        src="../../assets/images/Camera.png" alt="Open Camera"
                                                        class="d-block">
                                                    <img (click)="closeInsuranceCamera();" *ngIf="showInsuranceWebcam"
                                                        src="../../assets/images/NoCamera.png" alt="Close Camera"
                                                        class="d-block">
                                                </div>
                                                <!-- <hr /> -->
                                            </div>
                                            <!-- <hr> -->
                                            <!-- Web cam to capture insurance and license image logic Start -->

                                            <div class="webcam-capture">
                                                <webcam [trigger]="triggerObservable"
                                                    (imageCapture)="handleInsuranceImage($event)" [height]="300"
                                                    [width]="300" *ngIf="showInsuranceWebcam"
                                                    [allowCameraSwitch]="allowCameraSwitch"
                                                    [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions"
                                                    [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
                                                    (initError)="handleInitError($event)">
                                                </webcam>
                                            </div>
                                            <!-- <div class="noAppnts">
                                                    <p style="font-size: 12px;"> <i> Note : Capture Drivers
                                                            License both the sides</i></p>
                                                </div> -->
                                            <div class="capture-btn"
                                                *ngIf="showInsuranceWebcam && webcamInsuranceImages.length < 2">

                                                <button data-target="#bookSuccessfull" id="scheduleApp_capture"
                                                    (click)="triggerInsuranceSnapshot();"
                                                    class="btn btn-primary continue-btn mb-3">Capture
                                                </button>
                                            </div>
                                            <div class="snapshot">
                                                <!-- Thumbnail images -->
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-12 column"
                                                        (click)="currentInsuranceSlide(this,i)"
                                                        *ngFor="let webcamInsuranceImage of webcamInsuranceImages;let i = index">
                                                        <img (click)="clearInsuranceSnapshot(i);"
                                                            src="../../assets/images/DeleteBin.png" alt="Clear
                                                                                    Snapshot" class="d-block mb-3">
                                                        <img style="width: 100%;" class="demo cursor mb-3"
                                                            [src]="webcamInsuranceImage?.imageAsDataUrl" />
                                                    </div>
                                                </div>
                                                <!-- Full-width images with number text -->
                                                <!-- <div *ngIf="webcamInsuranceImage != null">
                                                    <div class="numbertext"> </div>
                                                    <img style="width: 100%;"
                                                        src={{webcamInsuranceImage?.imageAsDataUrl}}>
                                                </div> -->
                                            </div>

                                        </ng-container>
                                    </div>

                                    <ng-container
                                        *ngIf="seletcedIdCard !== '' && seletcedIdCard !== null &&  seletcedIdCard !== 'undefined'">
                                        <div class="col-lg-6 col-md-6 coll-sm-12">
                                            <h5 class="head-color">Existing Identification Card</h5>
                                            <div style="height: 200px;">
                                                <iframe id="id-card" frameBorder="0" width="100%"
                                                    height="200px"></iframe>
                                            </div>
                                        </div>
                                    </ng-container>


                                    <!-- Driving License Capture -->
                                    <div class="col-lg-12 col-md-12 col-sm-12 mt-4">
                                        <ng-container *ngIf="providerworkflow?.idImage">

                                            <div class="insurance-webcam">
                                                <label> Drivers License <span class="cameranotes">(Capture front side of
                                                        the Drivers License)</span></label>

                                                <div>
                                                    <img (click)="openLicenseCamera();" *ngIf="!showWebcam"
                                                        src="../../assets/images/Camera.png" alt="Open Camera"
                                                        class="d-block">
                                                    <img (click)="closeLicenseCamera();" *ngIf="showWebcam"
                                                        src="../../assets/images/NoCamera.png" alt="Close Camera"
                                                        class="d-block">
                                                </div>
                                                <!-- <hr /> -->
                                            </div>

                                            <div class="webcam-click">
                                                <webcam [trigger]="triggerLicenseObservable"
                                                    (imageCapture)="handleLicenseImage($event)" *ngIf="showWebcam"
                                                    [height]="300" [width]="300" [allowCameraSwitch]="allowCameraSwitch"
                                                    [switchCamera]="nextLicenseWebcamObservable"
                                                    [videoOptions]="videoOptions" [imageQuality]="1"
                                                    (cameraSwitched)="cameraWasSwitched($event)"
                                                    (initError)="handleInitError($event)"></webcam>
                                            </div>
                                            <!-- <div class="noAppnts col-lg-12 col-md-12 col-sm-12">
                                                <p style="font-size: 12px;"> <i> Note : Capture Insurance Card front
                                                        side</i></p>
                                            </div> -->
                                            <div class="capture-btns"
                                                *ngIf="showWebcam  && webcamLicenseImages.length < 1">

                                                <button data-target="#bookSuccessfull" id="scheduleApp_capture1"
                                                    (click)="triggerLicenseSnapshot();"
                                                    class="btn btn-primary continue-btn mb-3">Capture</button>
                                            </div>

                                            <br />
                                            <div class="snapshot">
                                                <!-- Thumbnail images -->
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 col-sm-12 column"
                                                        (click)="currentLicenseSlide(this,i)"
                                                        *ngFor="let webcamLicenseImage of webcamLicenseImages;let i = index">
                                                        <img (click)="clearLicenseSnapshot();"
                                                            src="../../assets/images/DeleteBin.png" alt="Clear Snapshot"
                                                            class="d-block mb-3">
                                                        <!-- <img style="width: 100%;" class="demo cursor"
                                                            [src]="webcamLicenseImage.imageAsDataUrl" /> -->
                                                    </div>
                                                </div>
                                                <!-- Full-width images with number text -->
                                                <div class="row" id="previewImages" *ngIf="webcamLicenseImage != null">
                                                    <div class="col-lg-6 col-md-6 col-sm-12 column">
                                                        <img style="width: 100%;"
                                                            src={{webcamLicenseImage?.imageAsDataUrl}}>
                                                    </div>
                                                </div>

                                            </div>

                                        </ng-container>
                                        <!-- Web cam logic End -->
                                    </div>

                                    <!-- insurance type fields -->
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="form-group mb-3">
                                            <label>Insurance Type <span class="required">*</span></label><br />
                                            <ng-container *ngFor="let insuType of insuranceTypes">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" formControlName="INSURANCE_TYPE"
                                                        (change)="changeMedicare()" type="radio" name="INSURANCE_TYPE"
                                                        [value]="insuType.value" id="{{getlabelId(insuType?.desc)}}insu">
                                                    <label class="form-check-label"
                                                        for="{{insuType?.desc}}insu">{{insuType?.desc}}</label>
                                                </div>
                                            </ng-container>
                                            <br />
                                            <span
                                                *ngIf="bookAppointmentForm?.get('INSURANCE_TYPE')?.errors?.required && bookAppointmentForm?.get('INSURANCE_TYPE')?.touched"
                                                class="error">Insurance Type is Required</span>
                                        </div>

                                        <!-- coverage effective Date -->
                                        <div class="form-group mb-3">
                                            <div
                                                *ngIf="bookAppointmentForm?.get('INSURANCE_TYPE')?.value === 'medicare'">
                                                <label>Coverage Effective Start Date <span
                                                        class="required">*</span></label><br />
                                                <input type="date" nam="medicare-date" class="form-control input-text"
                                                    formControlName="COVERAGE_EFFECTIVE_START_DATE"
                                                    id="COVERAGE_EFFECTIVE_START_DATE"
                                                    placeholder="Coverage Effective Start Date">
                                                <span
                                                    *ngIf="bookAppointmentForm?.get('COVERAGE_EFFECTIVE_START_DATE')?.errors?.required && bookAppointmentForm?.get('COVERAGE_EFFECTIVE_START_DATE')?.touched"
                                                    class="error">Coverage Effective Start Date is Required</span>
                                                <span
                                                    *ngIf="bookAppointmentForm?.get('COVERAGE_EFFECTIVE_START_DATE')?.errors?.invalidDate && bookAppointmentForm?.get('COVERAGE_EFFECTIVE_START_DATE')?.touched"
                                                    class="text-danger">
                                                    Please enter a valid year
                                                
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>

                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                <div class="back-continue-btns mt-4">
                                    <button type="button" class="btn btn-primary back-btn" id="scheduleApp_back"
                                        (click)="this.updatePannelFlags('displayPatientInfo');"
                                        style="margin-right: 15px;">Back</button>
                                    <button type="button" class="btn btn-primary continue-btn" id="scheduleApp_addApp"
                                        data-target="#bookSuccessfull" (click)="submitAppointment()">Add
                                        Appointment</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </section>


                <ng-container *ngIf="displayChiefComplaints">
                    <aw-wizard #previsitDocsWizard class="arc-wizard new-designed-left-arcs pre-visit-docs-wizard"
                        [awNavigationMode]="navigationMode" navigateBackward="allow" navigateForward="allow">
                        <ng-container *ngFor="let module of allCadModulesDetails;let i = index">
                            <aw-wizard-step stepTitle="{{module}}" [awOptionalStep]="navgationVal"
                                [canExit]="navgationVal">

                                <!-- <ng-container *ngIf="module === 'Chief Complaints'">
                                    <div class="bg-style chief-complaints">
                                        <h5>{{module}}</h5>
                                        <div class="row">
                                            <div class="col-lg-12 col-sm-12 col-md-12">
                                                <app-chief-complaints
                                                    [appointmentDetails]="dtAppointmentDetails" [previsitDocsWizard]="previsitDocsWizard"></app-chief-complaints>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container> -->

                                <!-- <ng-container *ngIf="module === 'ROS' || module === 'Review Of Systems'">
                                    <div class="bg-style chief-complaints">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 col-sm-12 col-md-12">
                                                <app-review-of-systems
                                                    [appointmentDetails]="dtAppointmentDetails" [previsitDocsWizard]="previsitDocsWizard" [moduleName]="module"></app-review-of-systems>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>  -->
                                <!-- *ngIf="module === 'Bone Mineral Density Scan'" -->
                                <ng-container *ngIf="module === currentStep">
                                    <div class="bg-style chief-complaints">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <h5 *ngIf="module !== 'ROS'" style="font-size: 18px !important;"
                                                    class="text-center">{{module}}</h5>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 col-sm-12 col-md-12">
                                                <app-bone-mineral-density-scan
                                                    [appointmentDetails]="dtAppointmentDetails"
                                                    [previsitDocsWizard]="previsitDocsWizard"
                                                    [moduleName]="module"></app-bone-mineral-density-scan>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- <ng-container *ngIf="module === 'Medical History'">
                                    <div class="bg-style chief-complaints">
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 col-sm-12 col-md-12">
                                                <app-medical-history [appointmentDetails]="dtAppointmentDetails" [previsitDocsWizard]="previsitDocsWizard"></app-medical-history>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container> -->

                                <div class="add-btn text-center mt-4">
                                    <!-- <button type="button" class="btn btn-primary continue-btn" awPreviousStep>Previous</button> -->
                                    <button type="button" class="btn btn-primary continue-btn pull-right" id="scheduleApp_continue_{{module}}"
                                        (click)="saveCadModules(module)">Continue</button>
                                </div>

                            </aw-wizard-step>
                        </ng-container>
                    </aw-wizard>
                </ng-container>

                <!-- Chief Complaints -->
                <!-- <section id="chief-complaints-details" *ngIf="displayChiefComplaints">
                    <div class="bg-style chief-complaints">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <h5>Reason for Your visit</h5>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-sm-12 col-md-12">
                                <app-chief-complaints
                                    [appointmentDetails]="dtAppointmentDetails"></app-chief-complaints>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4">
                            <button type="button" class="btn btn-primary continue-btn"
                                (click)="navigateToROS()">Continue</button>
                        </div>
                    </div>
                </section> -->


                <!-- Review os systems -->
                <!-- <section id="review-sytems-details" *ngIf="displayROS">
                    <form [formGroup]="bookAppointmentForm">
                        <div class="bg-style review-sytems">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <app-review-of-systems
                                        [appointmentDetails]="dtAppointmentDetails"></app-review-of-systems>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4">
                                <button data-target="#bookSuccessfull" (click)="saveCareFlowReviewOFSystems()"
                                    class="btn btn-primary continue-btn">Continue</button>
                            </div>
                        </div>

                    </form>
                </section> -->

                <!-- Bone Mineral Density Scan -->
                <!-- <section id="bone-density-sytems-details" *ngIf="displayBoneDensity">
                    <form [formGroup]="bookAppointmentForm">
                        <div class="bg-style review-sytems">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <app-bone-mineral-density-scan
                                        [appointmentDetails]="dtAppointmentDetails"></app-bone-mineral-density-scan>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4">
                                <button data-target="#bookSuccessfull" (click)="saveCareFlowReviewOFSystems()"
                                    class="btn btn-primary continue-btn">Continue</button>
                            </div>
                        </div>
                    </form>
                </section> -->

                <!-- Medical History -->
                <!-- <section id="bone-density-sytems-details" *ngIf="displayMedicalHistory">
                    <form [formGroup]="bookAppointmentForm">
                        <div class="bg-style review-sytems">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <app-medical-history
                                        [appointmentDetails]="dtAppointmentDetails"></app-medical-history>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 text-center mt-4">
                                <button data-target="#bookSuccessfull" (click)="saveCareFlowReviewOFSystems()"
                                    class="btn btn-primary continue-btn">Continue</button>
                            </div>
                        </div>
                    </form>
                </section> -->


                <!-- Consent Forms   (checkConscentForms)="checkConscentForms($event)"  -->
                <ng-container *ngIf="displayConsentForms">
                    <app-conscentforms [bookAppointmentForm]="bookAppointmentForm"></app-conscentforms>
                </ng-container>



            </div>

            <!-- spinner -->
            <div class="custom-loader justify-content-center" *ngIf="loadSpinner" id="spinnerDisplay">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

        </div>

    </div>

</div>



<!-- appointment related popups -->
<!-- Modal -->
<div id="book-appointment-modal" [style.display]="showModal ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{messageShown}}</h5>
                <table class="table table-striped table-bordered custom-table">
                    <thead>
                        <tr>
                            <th>Purpose of Visit</th>
                            <th>Date & Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{aapDetails?.app1}}</td>
                            <td>{{dateFormate(aapDetails?.app1Date)}} <!-- <span class="time">2:30 PM</span> -->
                            </td>
                        </tr>
                        <tr *ngIf="addlAppointmentsInfo.length>0">
                            <td>{{addlAppointmentsInfo[0]?.addlpurposeOfVisit}}</td>
                            <td>{{dateFormate(addlAppointmentsInfo[0]?.addldateTime)}}
                                <!-- <span class="time">4:10 PM</span> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
                <h6 *ngIf="visitRules && visitRules?.length>0">Reminder of the practice appointment policies:</h6>
                <ul class="visit-rules-list">
                    <li *ngFor="let rules of visitRules">
                        {{rules}}
                    </li>
                    <!-- <li>
                        Test Rule 2
                    </li> -->
                </ul>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="scheduleApp_ok"
                            (click)="navigateToChiefComplaints()">Ok</button>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div id="book-appointment-modal" [style.display]="showAppointmentSlots ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">No Appointments available for selected Date</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">

                        <button type="button" class="btn btn-default ok-book" (click)="okAppointments()" id="scheduleApp_ok1"
                            data-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Chief Complaints and ROS successfull submition popup -->
<div id="book-appointment-modal" [style.display]="previsitDocsSubmited ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">Thank you for submitting Pre-Visit Documentation</h5>
            </div>
            <div class="modal-footer">
                <!--<button type="button" class="btn btn-default ok-book" (click)="navigateToHomeScreen(false)"
                    data-dismiss="modal">Ok</button>-->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" (click)="navigateToConscentList()" id="scheduleApp_ok2"
                            data-dismiss="modal">Ok</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- consent forms submit successfull submition popup -->
<div id="book-appointment-modal" [style.display]="consentDocsSubmited ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">Thank you for submitting Pre-Visit Documentation</h5>
            </div>
            <div class="modal-footer">
                <!--<button type="button" class="btn btn-default ok-book" (click)="navigateToHomeScreen(false)"
                    data-dismiss="modal">Ok</button>-->
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" (click)="navigateToCADHomePage()" id="scheduleApp_ok3"
                            data-dismiss="modal">Ok</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Doctors dropdown validation popup -->
<div id="doctors-modal" [style.display]="doctorDropdownValidation ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{appointmentSlotsMessage}}</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" id="scheduleApp_ok4"
                            (click)="doctorDropdownValidation = false; appointmentSlotsMessage = ''"
                            data-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="book-appointment-modal" [style.display]="displaySuccessScreen ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">Thank you for submitting Pre-Visit Documentation</h5>
            </div>
            <div class="modal-footer">
                <!--<button type="button" class="btn btn-default ok-book" (click)="navigateToHomeScreen(true)"
                    data-dismiss="modal">Ok</button>-->

                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">

                        <button type="button" class="btn btn-default ok-book" (click)="navigateToConscentList()" id="scheduleApp_ok5"
                            data-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div id="book-appointment-modal" [style.display]="exceptionalModal ? 'block' : 'none'" class="modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{notifyText}}</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="scheduleApp_ok6"
                            (click)="exceptionalModal = false">Ok</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Medication Refills Modal -->
<div id="book-appointment-modal" [style.display]="medicationRefillsModal ? 'block' : 'none'" class="modal"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-body">
                <h5 style="text-align: center;padding: 15px;">{{medicationRefillsCheck}}</h5>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">

                        <button type="button" class="btn btn-default ok-book" data-dismiss="modal" id="scheduleApp_ok7"
                            (click)="medicationRefillsModal = false; ">Ok</button>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
