<div class="custom-loader justify-content-center" *ngIf="loadSpinner  && facilityId != '95'" id="spinnerDisplay">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="custom-loader justify-content-center" *ngIf="loadSpinner && facilityId == '95'" id="spinnerDisplay">
    <div class="visorspinner-border text-primary" role="status">
        <span class="sr-only">Visor Loading...</span>
    </div>
</div>